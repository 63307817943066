import React from "react"
import Goto from "./goto"
import ArrowRight from "../../../../assets/img/arrow-right.inline.svg"

import { StaticImage } from "gatsby-plugin-image"

const ContactFold = props => {
  return (
    <div className="kuda-entry--fold">
      <div className="kuda-entry--inner flex justify-between align-center">
        <div className="kuda-section--50">
          <div className="flex flex-column kuda-main--wrap">
            <h1 className="text-xl kuda-main--heading text-xlbold color-primary title-bottom--spacing">
              {props.title}
            </h1>
            <p className="card-main--heading text-semi-bold color-black mb-0">
              {props.subtitle}
            </p>
            <div className="flex flex-column">
              <div>
                <a href="https://selfhelp.kuda.com/" target="_blank" rel="noopener noreferrer" className={`learn-more color-primary flex align-center phone_number`}><span className="learn-more--icon"> Self-help  </span><span className="arrow-right--icon"><ArrowRight /></span></a>

              </div>
              <div>
                <a href={`tel:${props.tel}`} target="_blank" rel="noopener noreferrer" className={`learn-more color-primary flex align-center phone_number`}><span className="learn-more--icon">Call: {props.tel}</span><span className="arrow-right--icon"><ArrowRight /></span></a>


              </div>
              {props.supportMail &&
                <div>
                  <Goto
                    to={`mailto:${props.supportMail}`}
                    name={`Support: ${props.supportMail}`}
                    isExternal={true}
                  />
                </div>

              }

            </div>


          </div>
        </div>
        <div className="kuda-section--50">
          <div className="kuda-illustration--center">
            <StaticImage src={`../../../../assets/img/contactKuda.inline.svg`} placeholder="blurred" alt="kuda Microfinance Bank Savings, Spend, Budgeting." loading="eager" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactFold
