import React from "react"
import ContactUk from "../../components/body/pages/en-gb/contact"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/contact"}
      title="Contact Kuda Customer Service | The Money App for Africans"
      description="We're a bank that doesn't leave our customers hanging. Everything about us is on the app, but if you ever need help (or just want to chat), we’ve got you."
    />
    <ContactUk />
  </Layout>
)

export default ContactPage
